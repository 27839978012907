import React from "react"

import SEO from "../components/seo"

const TicketPage = () => (
  <div className="content">
    <SEO title="Organisation" />
    <h1>Organisation</h1>
    <p className={"short"}>
    Der Frachtraum schlägt Brücken und verbindet
    </p>
    <p className={"long"}>
    Die Konzerte finden im FrachtRaum statt, in einer ehemaligen Lagerhalle in Thun. Eine Location mit Ecken und Kanten – und mit viel Charme. Der FrachtRaum schlägt Brücken und verbindet. Sei es mit Co-Working, mit Ausstellungsflächen oder mit Events. Hier sind eine Handvoll Profis aus den Bereichen Kommunikation, Web, Grafik und Event angesiedelt. Sie alle verbindet eine Mission: «Be different and whatever you do – do it with love». Der FrachtRaum-Familie gehören Freigeister und Pioniere an, Menschen, die etwas bewegen und nachhaltige Projekte anreissen und umsetzen wollen. So auch Max Cuap unplugged.     </p>
  </div>
)

export default TicketPage